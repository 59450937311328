.how-it-works {
    font-family: 'Arial', sans-serif;
    margin: 20px;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.how-it-works h1 {
    color: #333;
    text-align: center;
    margin-bottom: 30px;
}

.how-it-works section {
    margin-bottom: 20px;
}

.how-it-works h2 {
    color: #2a9d8f;
    margin-bottom: 10px;
}

.how-it-works p {
    color: #333;
    line-height: 1.6;
}

/* Add responsive design for smaller screens */
@media (max-width: 768px) {
    .how-it-works {
        margin: 10px;
        padding: 10px;
    }
}