/* src/Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f0f0;
    padding: 10px;
}

.navbar-logo img {
    height: 50px;
}

.navbar-links a {
    margin-right: 20px;
    text-decoration: none;
    color: #333;
    font-weight: bold;
}

/* .navbar-links:hover {
    color: red;
} */

.navbar-account {
    color: #333;
}

.x {
    /* background-color: red; */
    border: 0;
    color: red;
    font-weight: bolder;
}

.x:hover {
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .navbar-links {
        display: grid;
    }
}