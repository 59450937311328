.about-page {
    font-family: 'Arial', sans-serif;
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.about-page h1 {
    color: #2c3e50;
    text-align: center;
}

.about-content section {
    margin-bottom: 15px;
}

.about-content h2 {
    color: #27ae60;
    margin-bottom: 5px;
}

.about-content p {
    color: #34495e;
    line-height: 1.5;
}

@media (max-width: 768px) {
    .about-page {
        margin: 10px;
        padding: 10px;
    }
}