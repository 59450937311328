.requester-dashboard {
    display: flex;
    height: 100vh;
}

.sidebar {
    flex-basis: 200px;
    background-color: #f3f3f3;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.sidebar button {
    padding: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    border: none;
    background-color: #f3f3f3;
    transition: background-color 0.3s;
}

.sidebar button:hover {
    background-color: #e1e1e1;
}

.sidebar button.active {
    font-weight: bold;
    color: white;
    background-color: #28a745;
}

.content {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
}

.profile-box {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 5px;
    margin: 20px;
    background-color: #f9f9f9;
}

.profile-box h3 {
    margin-bottom: 15px;
}

.profile-box p {
    margin: 5px 0;
    line-height: 1.6;
}

.new-task-form {
    /* max-width: 500px; */
    width: auto;
    margin: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.new-task-form h3 {
    margin-bottom: 20px;
}

.new-task-form input[type="text"],
.new-task-form input[type="number"],
.new-task-form textarea {
    width: 95%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.new-task-form button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.new-task-form button:hover {
    background-color: #0056b3;
}

.tasks-in-progress .task {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
}

.task-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.task-actions button {
    padding: 5px 10px;
    margin-right: 5px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
}

.task-actions button:hover {
    opacity: 0.8;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    min-width: 200px;
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    /* Adjust width as necessary */
}

.modal-content button {
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
}

.modal-content button:hover {
    background-color: #0056b3;
}