/* HomePage.css */
.home-header {
    background-color: rgb(213, 213, 213);
    text-align: center;
    padding: 20px;
    font-size: 24px;
    font-weight: bold;
}

.blockchain-connect {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin: 20px auto;
    text-align: center;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.blockchain-connect h2 {
    margin-bottom: 20px;
}

.blockchain-connect button {
    margin-bottom: 20px;
}

.tabs {
    display: flex;
    justify-content: center;
}

.tab-button {
    padding: 10px 20px;
    border: none;
    background-color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.tab-button.active {
    background-color: #f0f0f0;
}

.tab-content {
    display: none;
    padding: 20px;
    text-align: center;
}

.tab-content.active {
    display: block;
    background-color: #f0f0f0;
    border: none;
    /* justify-content: center; */
    width: auto;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input,
.form-group select {
    width: 50%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.sign-button {
    width: 100px;
    padding: 10px 15px;
    background-color: #4CAF50;
    /* A green background */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.connect-button {
    /* width: 100px; */
    padding: 10px 15px;
    background-color: #4CAF50;
    /* A green background */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.sign-button:hover {
    background-color: #45a049;
    /* Darker shade for hover effect */
}