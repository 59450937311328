.worker-dashboard {
    display: flex;
    height: 100vh;
    /* Full height */
}

.sidebar {
    flex-basis: 200px;
    /* Adjust the width of the sidebar */
    background-color: #f3f3f3;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.sidebar button {
    padding: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    border: none;
    background-color: #f3f3f3;
    transition: background-color 0.3s;
}

.sidebar button:hover {
    background-color: #e1e1e1;
}

.sidebar button.active {
    font-weight: bold;
    color: white;
    background-color: #007bff;
}

.content {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
    /* Add scroll for overflow content */
}

.profile-box {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 5px;
    margin: 20px;
    background-color: #f9f9f9;
}

.profile-box h3 {
    margin-bottom: 15px;
}

.profile-box p {
    margin: 5px 0;
    line-height: 1.6;
}

.tasks-available .task {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
}

.task-info {
    display: flex;
    flex-direction: column;
}

.task-title {
    font-size: 18px;
    font-weight: bold;
}

.task-reward {
    margin-top: 5px;
    font-weight: 500;
}

.task-actions button {
    padding: 5px 10px;
    margin-right: 5px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
}

.task-actions button:hover {
    background-color: #0056b3;
}

.tasks-pending-validation .task {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
}

.task-title {
    font-size: 18px;
    font-weight: bold;
}

.tasks-pending-validation button {
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    background-color: #28a745;
    color: white;
}

.tasks-pending-validation button:hover {
    background-color: #218838;
}

.tasks-pending-approval .task {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
}

.task-title {
    font-size: 18px;
    font-weight: bold;
}

.tasks-pending-approval button {
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
}

.tasks-pending-approval button:hover {
    background-color: #0056b3;
}



.tasks-completed .task {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
}

.tasks-completed button {
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    background-color: #28a745;
    color: white;
}

.tasks-completed button:hover {
    background-color: #218838;
}



.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    min-width: 200px;
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    /* Adjust width as necessary */
}

.modal-content button {
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
}

.modal-content button:hover {
    background-color: #0056b3;
}