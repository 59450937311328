/* Example styles in Footer.css */

.footer {
    background-color: #d5d5d5;
    text-align: center;
    padding: 20px;
    /* position: relative; */
    bottom: 0;
    /* width: 100%; */
    /* box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1); */
}